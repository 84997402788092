@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;700&family=Montserrat:wght@400;500;600;700;800;900&display=swap');

p, h1, h2, h3, h4, h5, h6, ul, li {
    margin: 0;
    padding: 0;
}

html, body {
    color: #333;
    padding: 0;
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html, body {
    height: 100%;
}

html {
    overflow-x: hidden;
}

body {
    min-height: 100vh;
    background-color: #E5EAF2;
    overflow-x: hidden;
    min-width: 375px;
}

* {
    box-sizing: border-box;
}

main {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    width: 1040px;

    @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 1rem;
    }
}

#__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

footer {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo {
    cursor: pointer;
    display: block;
    height: 70px;
    width: auto;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 35px;
    padding: 0 1rem;

    @media (max-width: 1024px) {
        height: auto;
        margin-top: 12px;
        margin-bottom: 20px;
        max-width: 500px;
        height: 60px;
        width: 100%;
    }
}

strong {
    color: #5F93F8;
}

button {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    touch-action: manipulation;
    user-select: none;
}

a {
    text-decoration: none;
}

@keyframes bounce-on-tuned {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}


.xl-only {
    @media (max-width: 1024px) {
        display: none;
    }
}